import {
  Skeleton,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

export type TableRow = {
  onClick?: () => void;
  values: any[];
};

export type TableValues = TableRow[];
export type TableHeader = string[];

function MyTable(props: {
  tableHeader: TableHeader;
  tableValues: TableValues;
}) {
  return (
    <TableContainer rounded="xl" boxShadow="xs" w="100%">
      <Table variant="simple">
        <Thead>
          <Tr>
            {props.tableHeader.map((name) => {
              return <Td>{name}</Td>;
            })}
          </Tr>
        </Thead>
        <Tbody>
          {props.tableValues.length === 0 ? (
            <Tr>
              {props.tableHeader.map((_) => {
                return (
                  <Td>
                    <Skeleton height="10px" />
                  </Td>
                );
              })}
            </Tr>
          ) : (
            props.tableValues.map((tableRow) => {
              return (
                <Tr onClick={tableRow.onClick}>
                  {tableRow.values.map((value) => {
                    return <Td>{value}</Td>;
                  })}
                </Tr>
              );
            })
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default MyTable;
