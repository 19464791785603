import { VStack, HStack, Text, Box, Heading, Divider } from "@chakra-ui/layout";
import { Switch, Select } from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/skeleton";
import { Tag } from "@chakra-ui/tag";
import Card from "../components/Card";
import MyTable from "../components/table/MyTable";
import Template from "../components/Template";
import { RootPage } from "../helpers/root_pages";
import examples from "../assets/AdversarialExample.png";
import { Input } from "@chakra-ui/react";

function ModelSettings() {
  return (
    <Template
      name={"Model Settings"}
      rootPage={RootPage.Pipelines}
      body={
        <VStack justify="start" align="baseline" h="100%" gap="0.75vw">
          <HStack>
            <Heading size="lg">Pipeline ID:</Heading>
            <Text fontSize="2xl">1001</Text>
          </HStack>

          <HStack>
            <Heading size="lg">Pipeline Name:</Heading>
            <Text fontSize="2xl">Fraud Model Dev</Text>
            <Box
              boxShadow="sm"
              borderRadius="lg"
              background="#5F22A6"
              color="white"
              alignContent="center"
              alignItems="center"
              padding="10px"
            >
              <Heading size="md" alignContent="center" alignItems="center">
                Change Name
              </Heading>
            </Box>
          </HStack>

          <HStack>
            <Heading size="lg" alignContent="flex-end">
              Pipeline Source:
            </Heading>
            <Text fontSize="2xl">
              https://github.com/GauntletAI/GauntletOrch
            </Text>
            <Box
              boxShadow="sm"
              borderRadius="lg"
              background="#5F22A6"
              color="white"
              alignContent="center"
              alignItems="center"
              padding="10px"
            >
              <Heading size="md" alignContent="center" alignItems="center">
                Change Source
              </Heading>
            </Box>
          </HStack>

          <Heading size="lg">Pipeline Evaluation Triggers:</Heading>
          <VStack align="baseline" paddingLeft="50px">
            <Heading size="lg" alignContent="flex-end">
              Automatic on Commits
            </Heading>
            <HStack>
              <Switch size="lg" />
              <Text fontSize="2xl">Every</Text>

              <Input placeholder="Number" />
              <Text fontSize="2xl">Commit(s)</Text>
            </HStack>
            <Heading size="lg" alignContent="flex-end">
              Automatic on Chron Job
            </Heading>
            <HStack>
              <Switch size="lg" />
              <Select placeholder="Select option">
                <option value="option1">Daily</option>
                <option value="option2">Weekly</option>
                <option value="option3">Monthly</option>
              </Select>
            </HStack>
          </VStack>
        </VStack>
      }
    />
  );
}

export default ModelSettings;
