export enum RootPage {
  FrontPage = 0,
  Dashboard = 1,
  Pipelines = 2,
  Evaluations = 3,
}

export const RootPageData = [
  {
    path: "/frontpage",
    name: "FrontPage",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
  },
  {
    path: "/pipeline_runs",
    name: "Pipelines",
  },
  {
    path: "/evaluations",
    name: "Evaluations",
  },
];
