import logo from '../assets/Logo2.png';
import styled from "@emotion/styled";
import { Box, VStack, Center } from '@chakra-ui/react'
import { Divider } from '@chakra-ui/react'
import { RootPage, RootPageData } from '../helpers/root_pages';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -10px 0px 15px;
  height: 100%;
`

function Sidebar(props: {rootPage: RootPage}) {
  const navigate = useNavigate();
  const { logout } = useAuth0();

  return (
    <Container>
      <Center h="15vh" onClick={() => {navigate("/")}}>
        <img src={logo} width="80%"/>
      </Center>
      <Divider marginBottom="6vh"/>
      <VStack
        spacing={4}
        align='start'
        justify='start'
        h='75vh'
        w="80%"
      >
        {
          Object.values(RootPage).map((val) => {
            console.log(val)
            if (typeof(val) === 'number') {
              return (
                <Box 
                  h="40px"
                  style={
                    props.rootPage === val ? 
                    {'color': '#7517DD', 'fontWeight': 'bold'} : 
                    {}
                  }
                  onClick={() => {navigate(RootPageData[val].path)}}
                >
                  {RootPageData[val].name}
                </Box>
              )
            }
          })
        }
      </VStack>
      <Divider marginBottom="6vh"/>
      <VStack
        spacing={4}
        align='start'
        justify='start'
        h='10vh'
        w="80%"
      >
        <Box 
          h="40px"
          onClick={() => logout({returnTo: window.location.origin})}
        >
          Logout
        </Box>
      </VStack>
    </Container>
  );
}

export default Sidebar;
