import { Box, Heading, HStack, Skeleton, Spacer, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Template from '../components/Template';
import { RootPage } from '../helpers/root_pages';
import ReactFlow, { Controls, Background } from 'reactflow';
import 'reactflow/dist/style.css';
import { useAuth0 } from '@auth0/auth0-react';

type state = {
  children: any[]
  pipeline: {
    failed_at: string | null,
    finished_at: string | null,
    started_at: string | null,
    id: string | null,
    name: string | null,
    root_step_run_id: string | null,
  }
}

var dagre = require("dagre");

const edges = [{ id: '1-2', source: '1', target: '2'}];

const nodes = [
  {
    id: '1',
    data: { label: 'Hello' },
    position: { x: 0, y: 0 },
    type: 'input',
  },
  {
    id: '2',
    data: { label: 'World' },
    position: { x: 100, y: 100 },
  },
];

function PipelineRun() {
  const { id } = useParams();
  const [data, setData] = useState<state | null>(null);
  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();

      const result = await axios.get(
          "http://127.0.0.1:5000/pipeline_runs/" + id?.toString(), {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      setData(result.data);

    })();
  }, []);

  useEffect(() => {
    var g = new dagre.graphlib.Graph();
    g.setGraph({});

    if (data !== null && data.children !== null) {
      for (var i = 0; i < data.children.length; i++) {
        const child = data.children[i];
        console.log(child)
        g.setNode(child.id, { label: child.id,  width: 200, height: 150 });
      }
    }

    if (data !== null && data.children !== null) {
      for (var i = 0; i < data.children.length; i++) {
        const child = data.children[i];
        if (child.parent_step_run_id !== '') {
          console.log(child.id, child.parent_step_run_id)
          g.setEdge(child.id, child.parent_step_run_id)
        }
      }
    }

    // dagre.layout(g);

    // g.nodes().forEach(function(v: any) {
    //       console.log("Node " + v + ": " + JSON.stringify(g.node(v)));
    // });
    // g.edges().forEach(function(e: any) {
    //     console.log("Edge " + e.v + " -> " + e.w + ": " + JSON.stringify(g.edge(e)));
    // });
  }, [data]);

  return (
    <Template
      name={id?.toString() ?? ''}
      rootPage={RootPage.Evaluations}
      body={
        <VStack
        justify='center'
        h='100%'
        gap="2%"
        >
          <HStack gap='3%' w='100%' h='20%' align='start'>
            <Box boxShadow="sm" borderWidth='1px' w='30%' padding='2%' borderRadius='lg' h='100%'>
              <Heading>Name</Heading>
              <Box h='20%'/>
              <Text>{
                data === null ?
                  <Skeleton height='20px'/> :
                  <>{data.pipeline.name}</>
              }</Text>
            </Box>
            <Box boxShadow="sm" borderWidth='1px' w='30%' padding='2%'  borderRadius='lg' h='100%'
              onClick={() => {
                if (data !== null) navigate('/step_runs/' + data.pipeline.root_step_run_id)
              }}
            >
              <Heading>Root Step Run</Heading>
              <Box h='20%'/>
              <Text>{
                data === null ?
                  <Skeleton height='20px'/> :
                  data.pipeline.root_step_run_id  
              }</Text>
            </Box>
            <Box boxShadow="sm" borderWidth='1px' w='30%' padding='2%'  borderRadius='lg' h='100%'>
              <Heading>Started At</Heading>
              <Box h='20%'/>
              <Text>{
                data === null ?
                  <Skeleton height='20px'/> :
                  data.pipeline.started_at  
              }</Text>
            </Box>
          </HStack>
          <HStack gap='3%' w='100%' h='70%' align='start'>
            <VStack w='40%' borderRadius='lg' h='100%' align='left'>
              <Heading>Steps</Heading>
              <TableContainer rounded='xl' boxShadow="sm" borderWidth='1px' w="100%">
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Function</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {
                      data === null ?
                      <Tr>
                        <Td><Skeleton height='10px'/></Td>
                        <Td><Skeleton height='10px'/></Td>
                      </Tr> :
                      data.children.map((child) => {
                        return (
                          <Tr
                            onClick={() => {
                              navigate('/step_runs/' + child.id)
                            }}
                          >
                            <Td>{child.id}</Td>
                            <Td>{child.function}</Td>
                          </Tr>
                        )
                      })
                    }
                  </Tbody>
                </Table>
              </TableContainer>
            </VStack>
            <Box boxShadow="sm" borderWidth='1px' w='53.7%' borderRadius='lg' h='100%'>
              <ReactFlow nodes={nodes} edges={edges}>
                <Background />
                <Controls />
              </ReactFlow>
            </Box>
          </HStack>
        </VStack>
      }
    />
  );
}

export default PipelineRun;
