import { Box, Center, VStack } from '@chakra-ui/react';
import styled from '@emotion/styled';

const PageTitle = styled.h1`
  color: #5F22A6;
  font-size: 4.5em;
  font-weight: bold;
  text-align: left;

  background: -webkit-linear-gradient(1deg, #6E2CC3, #A438FC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

function Content(props: {name: string, body: any}) {
  
  return (
    <VStack w="90%" h="100%" align="center" backgroundColor='white'>
      <VStack w="100%" align="start" justify="center" h='18vh'>
        <PageTitle>{props.name}</PageTitle>
      </VStack>
      <Box w="100%" h="100%">
        {props.body}
      </Box>
    </VStack>
  );
}

export default Content;
