import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TableContainer, Table, Thead, Tr, Th, Tbody, Td, Skeleton, useStatStyles, VStack, Button, Icon, Box, HStack, Tag } from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import Template from '../components/Template';
import { RootPage } from '../helpers/root_pages';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

function StepRuns() {
  const [runs, setRuns] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const navigate = useNavigate();

  const {getAccessTokenSilently} = useAuth0();

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();

      const result = await axios.get(
        "http://127.0.0.1:5000/step_runs", {
          params: {
            page: page
          }, 
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      setRuns(result.data);

    })();
  }, [page])

  const nextPage = () => {
    setPage(page+1);
  }

  const prevPage = () => {
    if (page > 0) {
      setPage(page-1);
    }
  }

  return (
    <Template
      rootPage={RootPage.Dashboard}
      name="Step Runs"
      body={
        <VStack>
          <TableContainer rounded='xl' boxShadow="xs" w="100%">
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Function</Th>
                  <Th>Started At</Th>
                  <Th>State</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  runs.length === 0 ?
                  (
                    <Tr>
                      <Td><Skeleton height='10px'/></Td>
                      <Td><Skeleton height='10px'/></Td>
                      <Td><Skeleton height='10px'/></Td>
                      <Td><Skeleton height='10px'/></Td>
                    </Tr>
                  ) :
                  runs.map((run) => {
                    const stateName = run.finished_at !== null ? 'Finished' : (run.failed_at !== null ? 'Failed' : 'Started');
                    const stateColor = run.finished_at !== null ? 'green' : (run.failed_at !== null ? 'red' : 'blue');

                    return (
                      <Tr
                        onClick={() => {
                          navigate('/step_runs/' + run.id)
                        }}
                      >
                        <Td>{run.id}</Td>
                        <Td>{run.function}</Td>
                        <Td>{run.started_at}</Td>
                        <Td><Tag colorScheme={stateColor}>{stateName}</Tag></Td>
                      </Tr>
                    )
                  })
                }
              </Tbody>
            </Table>
          </TableContainer>
          <HStack gap='4' h="70px">
          <Button onClick={prevPage}><ArrowBackIcon/></Button>
            <Button onClick={nextPage}><ArrowForwardIcon/></Button>
          </HStack>
        </VStack>
      }
    />
  );
}

export default StepRuns;
