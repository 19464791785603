import React, { useState } from 'react';

const GoalPage = () => {
  const [highAccuracy, setHighAccuracy] = useState(false);
  const [highReliability, setHighReliability] = useState(false);
  const [highSecurity, setHighSecurity] = useState(false);
  const [myTrainingSet, setMyTrainingSet] = useState(false);
  const [subsetOfTrainingSet, setSubsetOfTrainingSet] = useState(false);
  const [myModel, setMyModel] = useState(false);

  return (
    <div>
      <h1>What is your goal?</h1>
      <label>
        <input
          type="checkbox"
          checked={highAccuracy}
          onChange={() => setHighAccuracy(!highAccuracy)}
        />
        High Accuracy
      </label>
      <label>
        <input
          type="checkbox"
          checked={highReliability}
          onChange={() => setHighReliability(!highReliability)}
        />
        High Reliability
      </label>
      <label>
        <input
          type="checkbox"
          checked={highSecurity}
          onChange={() => setHighSecurity(!highSecurity)}
        />
        High Security
      </label>

      <h1>What data can you share?</h1>
      <label>
        <input
          type="checkbox"
          checked={myTrainingSet}
          onChange={() => setMyTrainingSet(!myTrainingSet)}
        />
        My training set
      </label>
      <label>
        <input
          type="checkbox"
          checked={subsetOfTrainingSet}
          onChange={() => setSubsetOfTrainingSet(!subsetOfTrainingSet)}
        />
        A subset of my training set
      </label>
      <label>
        <input
          type="checkbox"
          checked={myModel}
          onChange={() => setMyModel(!myModel)}
        />
        My model
      </label>
    </div>
  );
};

export default GoalPage;
