import { Button, Heading } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import Template from "../components/Template";
import { RootPage } from "../helpers/root_pages";

function FrontPage() {
  const navigate = useNavigate();

  return (
    <Template
      rootPage={RootPage.FrontPage}
      name="Front Page"
      body={
        <div style={{ gap: "50px" }}>
          <Heading size="2xl" color="" width="70%">
            Advex AI Accuracy, Reliability, and Security Engine
          </Heading>
          <Button
            onClick={() => {
              navigate("/uploadpage");
            }}
          >
            New Evaluation
          </Button>
          <Button
            onClick={() => {
              navigate("/");
            }}
            style={{ marginLeft: "20px" }}
          >
            View Old Evaluation
          </Button>
        </div>
      }
    />
  );
}

export default FrontPage;
