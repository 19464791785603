import { Box, Heading, HStack, Skeleton, Spacer, Table, TableContainer, Tag, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Template from '../components/Template';
import { RootPage } from '../helpers/root_pages';
import ReactFlow, { Controls, Background } from 'reactflow';
import 'reactflow/dist/style.css';
import { useAuth0 } from '@auth0/auth0-react';

function StepRun() {
  const { id } = useParams();
  const [data, setData] = useState<any>(null);
  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setData(null);
    (async () => {
      const token = await getAccessTokenSilently();

      const result = await axios.get(
          "http://127.0.0.1:5000/step_runs/" + id?.toString(), {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      setData(result.data);

    })();
  }, [id]);

  return (
    <Template
      name={id?.toString() ?? ''}
      rootPage={RootPage.Dashboard}
      body={
        <VStack
        justify='center'
        h='100%'
        gap="2%"
        >
          <HStack gap='3%' w='100%' h='15vh' align='start'>
            <Box boxShadow="sm" borderWidth='1px' w='30%' padding='1.5%' borderRadius='lg' h='100%'>
              <Heading>Function</Heading>
              <Box h='10%'/>
              <Text>{
                data === null ?
                  <Skeleton height='20px'/> :
                  <>{data.function}</>
              }</Text>
            </Box>
            <Box 
              boxShadow="sm" borderWidth='1px' w='30%' padding='1.5%'  borderRadius='lg' h='100%'
              onClick={() => {if (data !== null) navigate('/pipeline_runs/' + data.pipeline_run)}}
            >
              <Heading>Pipeline Run</Heading>
              <Box h='10%'/>
              <Text>{
                data === null ?
                  <Skeleton height='20px'/> :
                  data.pipeline_run 
              }</Text>
            </Box>
            <Box 
              boxShadow="sm" borderWidth='1px' w='30%' padding='1.5%'  borderRadius='lg' h='100%'
              onClick={() => {navigate('/step_runs/' + data.parent_step_run_id)}}
            >
              <Heading>Parent Step Run</Heading>
              <Box h='10%'/>
              <Text>{
                data === null ?
                  <Skeleton height='20px'/> :
                  data.parent_step_run_id  !== '' ?  data.parent_step_run_id : 'null'
              }</Text>
            </Box>
          </HStack>
          <HStack gap='3%' w='100%' h='15vh' align='start'>
            <Box boxShadow="sm" borderWidth='1px' w='30%' padding='1.5%' borderRadius='lg' h='100%'>
              <Heading>Started At</Heading>
              <Box h='10%'/>
              <Text>{
                data === null ?
                  <Skeleton height='20px'/> :
                  <>{data.started_at}</>
              }</Text>
            </Box>
            <Box 
              boxShadow="sm" borderWidth='1px' w='30%' padding='1.5%'  borderRadius='lg' h='100%'
              onClick={() => {if (data !== null) navigate('/pipeline_runs/' + data.pipeline_run)}}
            >
              <Heading>Completed At</Heading>
              <Box h='10%'/>
              <Text>{
                data === null ?
                  <Skeleton height='20px'/> :
                  data.finished_at !== null ? data.finished_at : (data.failed_at !== null ? data.failed_at : 'null')
              }</Text>
            </Box>
            <Box boxShadow="sm" borderWidth='1px' w='30%' padding='1.5%'  borderRadius='lg' h='100%'>
              <Heading>State</Heading>
              <Box h='10%'/>
              <Text>{
                data === null ?
                  <Skeleton height='20px'/> :
                  <Tag colorScheme={data.finished_at !== null ? 'green' : (data.failed_at !== null ? 'red' : 'blue')}>
                    {data.finished_at !== null ? 'Finished' : (data.failed_at !== null ? 'Failed' : 'Started')}
                  </Tag>
              }</Text>
            </Box>
          </HStack>
          <HStack gap='3%' w='100%' h='45vh' align='start'>
            <VStack w='40%' borderRadius='lg' h='100%' align='left'>
              <Heading>Children</Heading>
              <TableContainer rounded='xl' boxShadow="sm" borderWidth='1px' w="100%">
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Function</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {
                      data === null ?
                      <Tr>
                        <Td><Skeleton height='10px'/></Td>
                        <Td><Skeleton height='10px'/></Td>
                      </Tr> :
                      data.children.map((child: any) => {
                        return (
                          <Tr onClick={() => {navigate('/step_runs/' + child.id)}}>
                            <Td>{child.id}</Td>
                            <Td>{child.function}</Td>
                          </Tr>
                        )
                      })
                    }
                  </Tbody>
                </Table>
              </TableContainer>
            </VStack>
            <VStack w='40%' borderRadius='lg' h='100%' align='left'>
              <Heading>Input</Heading>
              <Box bgColor='black' h='100%' boxShadow="sm" borderWidth='1px' padding='4%'  borderRadius='lg' overflow='scroll'>
                <Text color='white'>{data === null ? <Skeleton height='20px' margin='2%'/> : data.input_artifact}</Text>
              </Box>
            </VStack>
            <VStack w='40%' borderRadius='lg' h='100%' align='left'>
              <Heading>{data !== null && data.failed_at !== null ? 'Error' : 'Output'}</Heading>
              <Box bgColor='black' h='100%' boxShadow="sm" borderWidth='1px' padding='4%'  borderRadius='lg' overflow='scroll'>
              <Text color='white'>{data === null ? <Skeleton height='20px' margin='2%'/> : data.output_artifact}</Text>
              </Box>
            </VStack>
            
          </HStack>
          
        </VStack>
      }
    />
  );
}

export default StepRun;
