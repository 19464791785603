import { VStack, HStack, Text, Box, Heading } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import { Tag } from "@chakra-ui/tag";
import Card from "../components/Card";
import MiniCard from "../components/MiniCard";
import MyTable from "../components/table/MyTable";
import Template from "../components/Template";
import { RootPage } from "../helpers/root_pages";

function Dashboard() {
  return (
    <Template
      name={"Dashboard"}
      rootPage={RootPage.Dashboard}
      body={
        <VStack justify="start" align="center" h="100%" gap="0.75vw">
          <HStack gap="0.75vw" w="100%" h="30%" align="start">
            <Card
              vertCenter={true}
              title="Average Score"
              value={
                <Heading size="2xl" color="green">
                  B+
                </Heading>
              }
            />
            <Card
              vertCenter={true}
              title="Evaluation Freq."
              value={
                <Heading size="2xl" color="green">
                  2 Days
                </Heading>
              }
            />
            <Card
              vertCenter={true}
              title="Completion Score"
              value={
                <Heading size="2xl" color="gold">
                  60%
                </Heading>
              }
            />
          </HStack>
          <HStack gap="0.75vw" w="100%" h="30%" align="start">
            <Card
              title="New Evaluation"
              value={
                <VStack justify="start" align="baseline" h="100%" w="100%" gap="0%">
                  <MiniCard invert={false} background="Gold" text="Auto-eval on commit 928e5b with score B+"/>
                  <MiniCard invert={true} background="Green" text="Completed Manual Eval with score A-" />
                  <MiniCard invert={true} background="Crimson" text="Completed Manual Eval with score C" />
                </VStack>
              }
            />
            <Card
              title="Latest Datasets"
              value={
                <VStack justify="start" align="baseline" h="100%" w="100%" gap="0%">
                  <MiniCard invert={true} background="DodgerBlue" text="New Dataset available for Fraud Model Dev"/>
                  <MiniCard invert={true} background="DodgerBlue" text="New Dataset available for Fraud Detection Proc"/>
                </VStack>
              }
            />
          </HStack>
          <HStack gap="0.75vw" w="100%" h="30%" align="start">
            <Card
              title="Monthly Reports"
              value={
                <VStack justify="start" align="baseline" h="100%" w="100%" gap="0%">
                  <MiniCard invert={true} background="DodgerBlue" text="View Technical Security Report"/>
                  <MiniCard invert={true} background="DodgerBlue" text="View Security Risk Report"/>
                  <MiniCard invert={true} background="DodgerBlue" text="Download Security Rating"/>
                </VStack>
              }
            />

            <Card
              title="Latest Model Scores"
              value={
                <VStack justify="start" align="baseline" h="100%" w="100%" gap="0%">
                  <MiniCard invert={true} background="Green" text="Fraud Model Dev: A-" />
                  <MiniCard invert={false} background="Gold" text="Fraud Model Prod: B+"/>
                  <MiniCard invert={false} background="Gold" text="Passport Model Dev: B" />
                </VStack>
              }
            />
          </HStack>

        </VStack>
      }
    />
  );
}

export default Dashboard;
