import { Box, Center, Heading, Skeleton, Text, VStack } from "@chakra-ui/react";

function Card(props: {
  title: string;
  value: any;
  w?: any;
  h?: any;
  vertCenter?: boolean;
  background?: any;
}) {
  return (
    <Box
      boxShadow="sm"
      borderWidth="1px"
      w={props.w ?? "100%"}
      h={props.h ?? "100%"}
      padding="1%"
      borderRadius="lg"
      background={props.background ?? "white"}
      alignContent="center"
    >
      <VStack h="100%" w="100%" justifyContent={props.vertCenter === undefined || !props.vertCenter ? "start" : "center"}>
        <Heading size="lg" fontWeight='300'>{props.title}</Heading>
        <Center w="100%" h={props.vertCenter === undefined || !props.vertCenter ? "100%" : ""} padding="0%">
          {props.value === null ? <Skeleton height="20px" /> : <>{props.value}</>}
        </Center>
      </VStack>
    </Box>
  );
}

export default Card;
