import { Button } from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GoalPage from "./GoalPage";
import Template from "../components/Template";
import { RootPage } from "../helpers/root_pages";

const UploadPage = () => {
  const [uploadModel, setUploadModel] = useState<File | null>(null);
  const [uploadDataset, setUploadDataset] = useState<File | null>(null);
  const [uploadInferenceSample, setUploadInferenceSample] =
    useState<File | null>(null);
  const [datasetAugmentation, setDatasetAugmentation] = useState(false);
  const [collectDataOutside, setCollectDataOutside] = useState(false);
  const [movementInvolved, setMovementInvolved] = useState(false);

  const [highAccuracy, setHighAccuracy] = useState(false);
  const [highReliability, setHighReliability] = useState(false);
  const [highSecurity, setHighSecurity] = useState(false);
  const [myTrainingSet, setMyTrainingSet] = useState(false);
  const [subsetOfTrainingSet, setSubsetOfTrainingSet] = useState(false);
  const [myModel, setMyModel] = useState(false);
  const navigate = useNavigate();

  const [trainingLink, setTrainingLink] = useState("");
  const [inferenceLink, setInferenceLink] = useState("");
  const [trainingData, setTrainingData] = useState("Full Training Data");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === "trainingLink") {
      setTrainingLink(value);
    } else if (name === "inferenceLink") {
      setInferenceLink(value);
    }
  };

  return (
    <Template
      name={"Upload Page"}
      rootPage={RootPage.FrontPage}
      body={
        <div>
          <div>
            <h1>What is your goal?</h1>
            <label>
              <input
                type="checkbox"
                checked={highAccuracy}
                onChange={() => setHighAccuracy(!highAccuracy)}
              />
              High Accuracy
            </label>
            <label>
              <input
                type="checkbox"
                checked={highReliability}
                onChange={() => setHighReliability(!highReliability)}
              />
              High Reliability
            </label>
            <label>
              <input
                type="checkbox"
                checked={highSecurity}
                onChange={() => setHighSecurity(!highSecurity)}
              />
              High Security
            </label>

            <h1>What data can you share?</h1>
            <label>
              <input
                type="checkbox"
                checked={myTrainingSet}
                onChange={() => setMyTrainingSet(!myTrainingSet)}
              />
              My training set
            </label>
            <label>
              <input
                type="checkbox"
                checked={subsetOfTrainingSet}
                onChange={() => setSubsetOfTrainingSet(!subsetOfTrainingSet)}
              />
              An Inference Sample
            </label>
            <label>
              <input
                type="checkbox"
                checked={myModel}
                onChange={() => setMyModel(!myModel)}
              />
              My model
            </label>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            {myTrainingSet && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <label>
                  Enter the Training set Link:
                  <input
                    name="trainingLink"
                    type="text"
                    value={trainingLink}
                    onChange={handleInputChange}
                    style={{ border: "2px solid purple", marginLeft: "10px" }}
                  />
                </label>
                <label>
                  Training Data:
                  <select
                    value={trainingData}
                    onChange={(event) => setTrainingData(event.target.value)}
                    style={{ border: "2px solid purple", marginLeft: "10px" }}
                  >
                    <option value="Full Training Data">
                      Full Training Data
                    </option>
                    <option value="Subset of Training Data">
                      Subset of Training Data
                    </option>
                  </select>
                </label>
              </div>
            )}
            {subsetOfTrainingSet && (
              <label>
                Enter the Inference Sample Link:
                <input
                  name="inferenceLink"
                  type="text"
                  value={inferenceLink}
                  onChange={handleInputChange}
                  style={{ border: "2px solid purple", marginLeft: "10px" }}
                />
              </label>
            )}
            {myModel && (
              <label>
                Enter the model file:
                <input
                  type="file"
                  accept=".pt"
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      setUploadInferenceSample(e.target.files[0]);
                    }
                  }}
                  style={{ marginLeft: "10px" }}
                />
              </label>
            )}
          </div>

          <h1>Is there movement involved in your inference environment?</h1>
          <label>
            <input
              type="checkbox"
              checked={datasetAugmentation}
              onChange={() => setDatasetAugmentation(!datasetAugmentation)}
            />
            Have you performed dataset augmentation?
          </label>
          <label>
            <input
              type="checkbox"
              checked={collectDataOutside}
              onChange={() => setCollectDataOutside(!collectDataOutside)}
            />
            Do you collect data outside?
          </label>
          <label>
            <input
              type="checkbox"
              checked={movementInvolved}
              onChange={() => setMovementInvolved(!movementInvolved)}
            />
            Is there movement involved (either camera or pictured object) in
            your inference environment?
          </label>

          <Button
            style={{ position: "absolute", bottom: 0, right: 0 }}
            onClick={() => {
              navigate("/evaluations");
            }}
          >
            Next
          </Button>
        </div>
      }
    />
  );
};

export default UploadPage;
