import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Skeleton,
  useStatStyles,
  VStack,
  Button,
  Icon,
  Box,
  HStack,
  Tag,
  Text,
  Heading,
} from "@chakra-ui/react";
import { wait } from "@testing-library/user-event/dist/utils";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MyTable from "../components/table/MyTable";
import Template from "../components/Template";
import { RootPage } from "../helpers/root_pages";

function Evaluations() {
  const [evals, setEvals] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    wait(1000);
    // axios.get(
    //   "http://127.0.0.1:5000/pipeline_runs", {
    //     params: {
    //       page: page
    //     }
    //   }
    // ).then((result) => {
    //   console.log(result);
    //   setRuns(result.data);
    // });
    setEvals([
      {
        id: 1008,
        started_at: "Nov 6 2022",
        state: "Completed",
        score: 0.9,
      },
      {
        id: 1007,
        started_at: "Feb 27 2022",
        state: "Completed",
        score: 0.8,
      },
      {
        id: 1006,
        started_at: "Feb 26 2022",
        state: "Completed",
        score: 0.9,
      },
      {
        id: 1005,
        started_at: "Jan 13 2022",
        state: "Completed",
        score: 0.7,
      },
      {
        id: 1004,
        started_at: "Oct 20 2021",
        state: "Completed",
        score: 0.6,
      },
      {
        id: 1003,
        started_at: "Sep 21 2021",
        state: "Completed",
        score: 0.32,
      },
      {
        id: 1002,
        started_at: "Oct 17 2021",
        state: "Completed",
        score: 0.3,
      },

      {
        id: 1001,
        started_at: "Sep 5 2021",
        state: "Completed",
        score: 0.05,
      },
    ]);
  }, [page]);

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const getScore = (score: number) => {
    score = score / 2 + 0.5;
    if (score > 0.97) {
      return "A+";
    } else if (score > 0.93) {
      return "A";
    } else if (score > 0.9) {
      return "A-";
    } else if (score > 0.87) {
      return "B+";
    } else if (score > 0.83) {
      return "B";
    } else if (score > 0.8) {
      return "B-";
    } else if (score > 0.77) {
      return "C+";
    } else if (score > 0.73) {
      return "C";
    } else if (score > 0.7) {
      return "C-";
    } else if (score > 0.67) {
      return "D+";
    } else if (score > 0.63) {
      return "D";
    } else if (score > 0.6) {
      return "D-";
    } else if (score > 0.57) {
      return "F+";
    } else if (score > 0.53) {
      return "F";
    } else if (score > 0.5) {
      return "F-";
    }
  };

  const getScoreColor = (score: number) => {
    score = score / 2 + 0.5;
    if (score > 0.9) {
      return "green";
    } else if (score > 0.8) {
      return "orange";
    } else if (score > 0.7) {
      return "orange";
    } else if (score > 0.6) {
      return "red";
    } else if (score > 0.5) {
      return "red";
    } else {
      return "pink";
    }
  };

  const navigate = useNavigate();

  const getTableValues = () => {
    return evals.map((evl) => {
      return {
        values: [
          evl.id,
          evl.started_at,
          evl.state,
          <Text color={getScoreColor(evl.score)}>{getScore(evl.score)}</Text>,
          <Td
            onClick={() => {
              navigate("/EvaluationExplore");
            }}
          >
            <Box
              boxShadow="sm"
              borderRadius="lg"
              background="#5F22A6"
              color="white"
              alignContent="center"
              alignItems="center"
              padding="10px"
            >
              <Heading size="xs" alignContent="center" alignItems="center">
                Edit
              </Heading>
            </Box>
          </Td>,
        ],
      };
    });
  };

  return (
    <Template
      name="Evaluations"
      rootPage={RootPage.Evaluations}
      body={
        <VStack>
          <MyTable
            tableHeader={["ID", "Started At", "State", "Score"]}
            tableValues={getTableValues()}
          />
          <HStack gap="4" h="100px">
            <Button onClick={prevPage}>
              <ArrowBackIcon />
            </Button>
            <Button onClick={nextPage}>
              <ArrowForwardIcon />
            </Button>
          </HStack>
        </VStack>
      }
    />
  );
}

export default Evaluations;
