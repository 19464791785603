import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { VStack, HStack, Text, Box, Heading } from "@chakra-ui/layout";
import { Button, Img } from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/skeleton";
import { Tag } from "@chakra-ui/tag";
import Card from "../components/Card";
import MiniCard from "../components/MiniCard";
import MyTable from "../components/table/MyTable";
import Template from "../components/Template";
import { RootPage } from "../helpers/root_pages";
import before from "../assets/before.png";
import after from "../assets/after.png";

function Eval() {
  return (
    <Template
      name={"Evaluation 001"}
      rootPage={RootPage.Evaluations}
      body={
        <VStack justify="start" align="center" h="100%" gap="0.75vw">
          <HStack gap="0.75vw" w="100%" h="25%" align="start">
            <Card
              vertCenter={true}
              title="Trigger"
              value={
                <Heading size="lg">
                  Commit 926e5b
                </Heading>
              }
            />
            <Card
              vertCenter={true}
              title="Model"
              value={
                <Heading size="lg">
                  Object Detector
                </Heading>
              }
            />
            <Card
              vertCenter={true}
              title="Dataset"
              value={
                <Heading size="lg">
                  Bounding Boxes
                </Heading>
              }
            />
          </HStack>
          <HStack gap="0.75vw" w="100%" h="25%" align="start">
            <Card
              vertCenter={true}
              title="Score"
              value={
                <Heading size="lg" color="gold">
                  B+
                </Heading>
              }
            />
            <Card
              vertCenter={true}
              title="Survival Rate"
              value={
                <Heading size="lg" color="green">
                  150,126/160,000
                </Heading>
              }
            />
            <Card
              vertCenter={true}
              title="Speed"
              value={
                <Heading size="lg" color="Crimson">
                  15 Minutes
                </Heading>
              }
            />
          </HStack>
          <HStack gap="0.75vw" w="100%" h="40%" align="start">
            <Card
              title="Examples"
              vertCenter={false}
              value={
                <HStack justify="center" align="center" h="100%" w="100%" gap="5%">
                  <Button>
                    <ArrowBackIcon />
                  </Button>
                  <VStack>
                    <Img borderRadius='5px' width="150px"  src={before}/>
                    <Text>"Stop Sign"</Text>
                  </VStack>
                  <ArrowForwardIcon />
                  <VStack>
                    <Img borderRadius='5px' width="150px"  src={after}/>
                    <Text>"Speed Limit 100"</Text>
                  </VStack>
                  <Button>
                    <ArrowForwardIcon />
                  </Button>
                </HStack>
              }
            />

  
          </HStack>
		<a target="_blank" href="https://console.cloud.google.com/storage/browser/advex_customer_example/datasets/results">View Adversarial Dataset</a>
        </VStack>
      }
    />
  );
}

export default Eval;
