import { useAuth0 } from "@auth0/auth0-react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Skeleton,
  useStatStyles,
  VStack,
  Button,
  Icon,
  Box,
  HStack,
  Heading,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Template from "../components/Template";
import { RootPage } from "../helpers/root_pages";

function PipelineRuns() {
  const [runs, setRuns] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    // (async () => {
    //   const token = await getAccessTokenSilently();

    //   const result = await axios.get(
    //     "http://127.0.0.1:5000/pipeline_runs", {
    //       params: {
    //         page: page
    //       },
    //       headers: {
    //        Authorization: `Bearer ${token}`
    //       }
    //     }
    //   )

    setRuns([
      {
        id: 1010,
        name: "Scene Detection Dev -- Manual",
        latest_run: "Aug 17 2022",
        latest_score: 0.2,
        checklist_completion: "20%",
      },
      {
        id: 1009,
        name: "Scene Detection Prod -- Manual",
        latest_run: "Jun 12 2022",
        latest_score: 0.95,
        checklist_completion: "100%",
      },
      {
        id: 1008,
        name: "Facial Detection Prod -- Automatic Sundayas at 12am",
        latest_run: "Mar 29 2022",
        latest_score: 0.8,
        checklist_completion: "100%",
      },
      {
        id: 1007,
        name: "License Classifier Dev -- Automatic upon commit",
        latest_run: "Feb 27 2022",
        latest_score: 0.9,
        checklist_completion: "40%",
      },
      {
        id: 1006,
        name: "Object Detection Model Prod -- Automatic Sundays at 12am",
        latest_run: "Feb 26 2022",
        latest_score: 0.8,
        checklist_completion: "20%",
      },
      {
        id: 1005,
        name: "New Fraud Model Beta -- Automatic every 10 commits",
        latest_run: "Jan 13 2022",
        latest_score: 0.3,
        checklist_completion: "50%",
      },
      {
        id: 1004,
        name: "Check Verification Model Dev -- Automatic upon commit",
        latest_run: "Oct 20 2021",
        latest_score: 0.9,
        checklist_completion: "80%",
      },
      {
        id: 1003,
        name: "Distilled Fraud Model Dev -- Automatic upon commit",
        latest_run: "Sep 21 2021",
        latest_score: 0.92,
        checklist_completion: "100%",
      },
      {
        id: 1002,
        name: "Fraud Model Prod -- Automatic Sundays at 12am",
        latest_run: "Oct 17 2021",
        latest_score: 0.8,
        checklist_completion: "40%",
      },

      {
        id: 1001,
        name: "Fraud Model Dev -- Automatic upon commit",
        latest_run: "Sep 5 2021",
        latest_score: 0.8,
        checklist_completion: "20%",
      },
    ]);
  }, [page]);

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const navigate = useNavigate();

  return (
    <Template
      name="Model Runs"
      rootPage={RootPage.Pipelines}
      body={
        <VStack>
          <TableContainer rounded="xl" boxShadow="xs" w="100%">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Name</Th>
                  <Th>Latest Run</Th>
                  <Th>Latest Score</Th>
                  <Th>Checklist Completion</Th>
                </Tr>
              </Thead>
              <Tbody>
                {runs.length === 0 ? (
                  <Tr>
                    <Td>
                      <Skeleton height="10px" />
                    </Td>
                    <Td>
                      <Skeleton height="10px" />
                    </Td>
                    <Td>
                      <Skeleton height="10px" />
                    </Td>
                  </Tr>
                ) : (
                  runs.map((run) => {
                    return (
                      <Tr>
                        <Td
                          onClick={() => {
                            navigate("/pipeline_runs/" + run.id.toString());
                          }}
                        >
                          {run.id}
                        </Td>
                        <Td
                          onClick={() => {
                            navigate("/pipeline_runs/" + run.id.toString());
                          }}
                        >
                          {run.name}
                        </Td>
                        <Td
                          onClick={() => {
                            navigate("/pipeline_runs/" + run.id.toString());
                          }}
                        >
                          {run.latest_run}
                        </Td>
                        <Td
                          onClick={() => {
                            navigate("/pipeline_runs/" + run.id.toString());
                          }}
                        >
                          {run.latest_score}
                        </Td>
                        <Td
                          onClick={() => {
                            navigate("/pipeline_runs/" + run.id.toString());
                          }}
                        >
                          {run.checklist_completion}
                        </Td>
                        <Td
                          onClick={() => {
                            navigate("/modelsettings");
                          }}
                        >
                          <Box
                            boxShadow="sm"
                            borderRadius="lg"
                            background="#5F22A6"
                            color="white"
                            alignContent="center"
                            alignItems="center"
                            padding="10px"
                          >
                            <Heading
                              size="xs"
                              alignContent="center"
                              alignItems="center"
                            >
                              Edit
                            </Heading>
                          </Box>
                        </Td>
                      </Tr>
                    );
                  })
                )}
              </Tbody>
            </Table>
          </TableContainer>
          <HStack gap="4" h="100px">
            <Button onClick={prevPage}>
              <ArrowBackIcon />
            </Button>
            <Button onClick={nextPage}>
              <ArrowForwardIcon />
            </Button>
          </HStack>
        </VStack>
      }
    />
  );
}

export default PipelineRuns;
