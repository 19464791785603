import { createContext, useEffect, useState } from "react";
import PipelineRuns from "./pages/PipelineRuns";
import StepRuns from "./pages/StepRuns";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PipelineRun from "./pages/PipelineRun";
import StepRun from "./pages/StepRun";
import Evaluations from "./pages/Evaluations";
import Evaluation from "./pages/Evaluation";
import Attack from "./pages/Attack";
import { useAuth0 } from "@auth0/auth0-react";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import EvaluationExplore from "./pages/EvaluationExplore";
import ModelSettings from "./pages/ModelSettings";
import axios from "axios";
import Eval from "./pages/Eval";
import FrontPage from "./pages/FrontPage";
import GoalPage from "./pages/GoalPage";
import UploadPage from "./pages/UploadPage";

export interface StateType {
  page: number;
}

export interface StateContextType {
  state: StateType | null;
  setState(state: StateType): void;
}

export const StateContext = createContext<StateContextType>(null!);

const router = createBrowserRouter([
  {
    path: "frontpage",
    element: <FrontPage />,
  },
  {
    path: "uploadpage",
    element: <UploadPage />,
  },
  {
    path: "goalpage",
    element: <GoalPage />,
  },
  {
    path: "",
    element: <Dashboard />,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "evaluationexplore",
    element: <Eval />,
  },
  {
    path: "modelsettings",
    element: <ModelSettings />,
  },
  {
    path: "pipeline_runs",
    element: <PipelineRuns />,
  },
  {
    path: "pipeline_runs/:id",
    element: <PipelineRun />,
  },
  {
    path: "/step_runs",
    element: <StepRuns />,
  },
  {
    path: "step_runs/:id",
    element: <StepRun />,
  },

  {
    path: "evaluations",
    element: <Evaluations />,
  },
  {
    path: "evaluations/:id",
    element: <Evaluation />,
  },
  {
    path: "attacks/",
    element: <Attack />,
  },

  {
    path: "attacks/:id",
    element: <Attack />,
  },
]);

function App() {
  const [state, setState] = useState<StateType>({page: 0})
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const token = await getAccessTokenSilently();
        const result = await axios.get(
            "http://127.0.0.1:5000/check_signed_up", {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        console.log(result);
      })();

    }

  }, [isAuthenticated]);

  return (
    <StateContext.Provider value={{ state, setState }}>
      {
        // isAuthenticated ?
        //   <RouterProvider router={router} /> :
        //   <Login/>
        <RouterProvider router={router} />
      }
    </StateContext.Provider>
  );
}

export default App;
